<template>
	<div :class="classes">
		<input v-model.lazy="block.content" type="url" placeholder="Paste a video URL here" />
		<div v-if="embedded" class="embedded" v-html="embedded"></div>

		<div class="transcript">
			<RichEditor v-model="block.meta.transcript" :editable="block.own" :inline="true" :block-id="block.id" placeholder="Add a transcript..." @input="$emit('edit')" />
		</div>
	</div>
</template>

<script>
	import {getEmbedded} from '../../inc/videoEmbeds';
	import RichEditor from '../RichEditor';

	export default {
		name: 'EditorBlockVideo',
		components: {
			RichEditor
		},
		props: {
			block: {
				type: Object,
				required: true
			}
		},
		computed: {
			embedded() {
				return getEmbedded(this.block.content);
			},
			classes() {
				return `block-inner align-${this.block.meta.align}`;
			}
		},
		watch: {
			embedded(value) {
				if(value) {
					this.$emit('edit');
				}
			}
		},
		created() {
			if(typeof this.block.content !== 'string') {
				this.block.content = '';
			}

			if(typeof this.block.meta !== 'object') {
				this.$set(this.block, 'meta', {});
			}

			if(!Object.keys(this.block.meta).length) {
				this.$set(this.block.meta, 'align', 'left');
			}
		}
	};
</script>

<style lang="scss" scoped>
	.embedded {
		display: flex;
		flex-flow: row nowrap;
		margin-bottom: $default_padding / 2;
	}

	.align-center .embedded {
		justify-content: center;
	}

	.align-right .embedded {
		justify-content: flex-end;
	}
</style>